<template>
    <div class="item">
        <div class="pic" @click="goDetail"><img :src="data.ImageUrl" alt=""></div>
        <div class="txt">
            <div class="top">
                <h2 class="dot" @click="goDetail" v-html="data.Name"></h2>
                <div class="des dot2" v-html="data.Info"></div>
                <div class="tag_box" >
                    <Tag v-for="(item,index) in tags" :key="index" :name="item"/>
                </div>
            </div>
            <div class="time">
                发布时间：{{time}}
            </div>
        </div>
        <div class="handle">
            <div class="handle_item" :class="data.IsCollection?'active':''"  @click="toggleCollect">
                <svg-icon icon-class="icon_collect"></svg-icon>
                <span v-if="data.IsCollection">取消</span>
                <span v-else>收藏</span>
            </div>
            <div class="handle_item" @click="goDetail">
                <svg-icon icon-class="icon_eye"></svg-icon>
                <span>查看</span>
            </div>
        </div>
    </div>
</template>
<script>
import Tag from '@/components/common/Tag'
import { mapState, mapActions, mapMutations } from 'vuex'
import { ToggleCollectModel } from '@/api/collection'
export default {
    props:['data','type'],
    components:{
        Tag
    },
    data(){
        return{
        }
    },
    computed:{
        tags(){
            this.data.Tags = this.data.Tags || ''
            return this.data.Tags.split(',')
        },
        time(){
            return this.data.CreateTime.split(' ')[0].split('/').join('-')
        },
        ...mapState({
            userInfo: (state) => state.user.userInfo,
        }),
    },
    methods:{
        goDetail(item){
            this.$router.push({name: 'learning-info', params: {id: this.data.Id}})
        },
        toggleCollect(){
            const user = this.userInfo.LoginName
            if(!user){
                this.$message.info('登录之后才能收藏，请先登录！')
                this.$store.commit('saveLoginDialog', true)
            }else{
                ToggleCollectModel(this.data.Id).then(res => {
                    const msg = this.data.IsCollection ? '取消成功':'收藏成功'
                    this.$message.success(msg)
                    this.data.IsCollection = !this.data.IsCollection
                }).catch(e => e)
            }
        }
    }
}
</script>
<style lang="less" scoped>
.item{
    padding: 20px;
    display: flex;
    background-color: #f5f5f5;
    position: relative;
    transition: .3s;
    &:hover{
        box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
    }
    .pic{
        flex-shrink: 0;
        cursor: pointer;
        margin-right: 40px;
        img{
            width: 120px;
            height: 180px;
            object-fit: cover;
        }
    }
    .txt{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-right: 156px;
        flex: 1;
        h2{
            font-size: 17px;
            color: rgb(51, 51, 51);
            line-height: 1.556;
            // font-weight: bold;
            cursor: pointer;
            margin-bottom: 15px;
            &:hover{
                color: #f6a75c !important;
                span{
                    color: #f6a75c !important;
                }
            }
        }
        .des{
            font-size: 16px;
            color: rgb(85, 85, 85);
            line-height: 1.5;
            margin-bottom: 20px;
        }
        .time{
            font-size: 16px;
            color: rgb(136, 136, 136);
        }

    }
    .handle{
        display: flex;
        align-items: center;
        padding: 0 30px;
        margin: 30px 0;
        border-left: 1px solid #d5d5d5;
        .handle_item{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: #999;
            cursor: pointer;
            padding: 20px;
            &:hover,
            &.active{
                color: #f6a75c;
            }
        }
    }
}
</style>