<template>
    <div class="breadcrumb" :class="type">
        <a-breadcrumb separator="•">
            <a-breadcrumb-item>
                <router-link to="/">
                    <svg-icon
                        icon-class="icon_home"
                        class="icon_home"
                    ></svg-icon>
                    <span>首页</span>
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item v-for="(item, index) in list" :key="item.path">
                <span class="dot" v-if="index == list.length - 1">
                    {{ item.meta.title }}
                </span>
                <router-link :to="{ name: item.name }" v-else>
                    <span>{{ item.meta.title }}</span>
                </router-link>
            </a-breadcrumb-item>
        </a-breadcrumb>
    </div>
</template>
<script>
import routes from '@/router'
export default {
    props: {
        type: {
            type: String,
            default: 'white',
        },
        title: String,
    },
    data() {
        return {
            list: [],
        }
    },
    watch: {
        $route(v) {
            this.getBreadcrumb()
        },
        title() {
            this.getBreadcrumb()
        },
    },
    methods: {
        getBreadcrumb() {
            let matched = this.$route.matched.filter(
                item => item.meta && item.meta.title
            )
            if (this.title) {
                matched.push({
                    path: this.title,
                    meta: {
                        title: this.title,
                    },
                    title: true,
                })
            }
            this.list = matched
        },
    },
    created() {
        this.getBreadcrumb()
    },
}
</script>
<style lang="less" scoped>
.breadcrumb {
    height: 70px;
    display: flex;
    align-items: center;
    * {
        font-size: 1em;
        flex-shrink: 0;
    }
}
:deep(.ant-breadcrumb) {
    display: flex;
    align-items: center;
    .ant-breadcrumb-link,
    & > span {
        display: flex;
        align-items: center;
        &:last-of-type {
            flex: 1;
            // flex-grow: 0;
        }
    }
    .dot {
        width: 100%;
        max-width: 400px;
    }
}
.white {
    opacity: 0.5;
    color: #fff;
    font-size: 1em;
    svg {
        color: #fff;
    }
    a {
        color: #fff;
        display: flex;
        align-items: center;
    }
    :deep(.ant-breadcrumb-link),
    :deep(.ant-breadcrumb-separator) {
        color: #fff;
    }
}
.grey {
    color: #999;
    border-bottom: 1px solid #e5e5e5;
    svg {
        color: #bbb;
    }
    a {
        color: #999;
        display: flex;
        align-items: center;
    }
    :deep(.ant-breadcrumb-link),
    :deep(.ant-breadcrumb-separator) {
        color: #999;
    }
}
:deep(.ant-breadcrumb-link) a:hover {
    color: #00ffc0 !important;
}
.icon_home {
    font-size: 1.25em;
    margin-right: 10px;
}
:deep(.ant-breadcrumb-separator) {
    // font-family: '宋体';
    font-size: 12px;
}
</style>
