import request from '@/utils/request'
const baseURL = '/API/User/Model'
export const GetList = function (data) {// 网站问题反馈
    return request({
        url: "/API/User/Model/GetList",
        method: 'GET',
        params: data
    })
}
export const GetData = function (data) {// 网站问题反馈
    return request({
        url: "/API/User/Model/GetData",
        method: 'GET',
        params: data
    })
}
export const GetListByCardId = function (data) {// 网站问题反馈
    return request({
        url: "/API/User/Model/GetListByCardId",
        method: 'GET',
        params: data
    })
}

export const GetListByModelId = function (data) {// 网站问题反馈
    return request({
        url: "/API/User/Model/GetListBySubjectId",
        method: 'GET',
        params: data
    })
}

export const GetListByIds = function (data) {
    return request({
        method: "get",
        url: "/API/User/Model/GetListByIds",
        params: data,
    });
};


export const GetListSenior = function (data) {
    return request({
        method: "get",
        url: "/API/User/Model/GetListSenior",
        params: data,
    });
};




export const SetHours = function (data) {
    return request({
        method: "POST",
        url: "/API/User/Model/SetHours",
        params: data,
    });
};


