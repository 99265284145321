<template>
    <!-- <div class="search">
        <template v-for="(item, index) in params">
            <div
                :class="['item', item.class]"
                :key="index"
                v-if="item.type == 'select'"
            >
                <a-select
                    size="large"
                    :placeholder="item.name"
                    v-model="item.value"
                >
                    <a-icon slot="suffixIcon" type="caret-down" />
                    <a-select-option
                        v-for="option in item.options"
                        :key="option.id"
                        :value="option.id"
                    >
                        {{ option.name }}
                    </a-select-option>
                </a-select>
            </div>
            <div
                :class="['item', item.class]"
                :key="index"
                v-if="item.type == 'input'"
            >
                <a-input
                    :placeholder="item.name"
                    v-model="item.value"
                    allow-clear
                    @keyup.enter="search"
                />
            </div>
        </template>
        <div class="item btn">
            <a-button class="submit" type="primary" @click="search">
                检索
            </a-button>
        </div>
    </div> -->
    <div class="advanced-search" ref="searchWrap">
        <div class="search-top">
            <a-row :gutter="10">
                <a-col :span="4"></a-col>
                <a-col :span="4">
                    <a-select
                        size="large"
                        v-model="searchType"
                        placeholder="请选择"
                    >
                        <a-select-option
                            v-for="item in modelSearchtype"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-col>
                <a-col :span="12">
                    <a-input-search
                        v-model="searchValue"
                        placeholder="请输入检索内容"
                        @search="onSearch"
                    />
                </a-col>
                <a-col :span="4">
                    <div class="switch">
                        <a-switch v-model="isAdvabde" />
                        高级检索
                    </div>
                </a-col>
            </a-row>
        </div>
        <div class="search-add">
            <a-row
                :gutter="10"
                v-for="(add, index) in searchAddarr"
                :key="add.id"
            >
                <a-col :span="4">
                    <a-select
                        size="large"
                        v-model="add.isAndOr"
                        placeholder="请选择"
                    >
                        <a-select-option
                            v-for="item in andOr"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-col>
                <a-col :span="4">
                    <a-select
                        size="large"
                        v-model="add.searchType"
                        placeholder="请选择"
                    >
                        <a-select-option
                            v-for="item in modelSearchtype"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </a-col>
                <a-col :span="12">
                    <a-input-search
                        v-model="add.value"
                        placeholder="请输入检索内容"
                        allowClear
                        @search="onSearch"
                    />
                </a-col>
                <a-col :span="4">
                    <div class="handle">
                        <a-tooltip class="box-item">
                            <template slot="title">删除</template>
                            <div class="reduce">
                                <a-icon
                                    type="minus-circle"
                                    @click="reduceItem(index)"
                                />
                            </div>
                        </a-tooltip>
                        <a-tooltip
                            class="box-item"
                            v-if="index == searchAddarr.length - 1"
                        >
                            <template slot="title">添加</template>
                            <div class="reduce">
                                <a-icon type="plus-circle" @click="addItem" />
                            </div>
                        </a-tooltip>
                    </div>
                </a-col>
            </a-row>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {
                return {
                    category: '',
                    group: '',
                    orderBy: 'Sort desc',
                    type: '',
                    pageIndex: 1,
                    pageSize: 12,
                }
            },
        },
    },
    data() {
        return {
            modelSearchtype: [
                {
                    value: 'fullText',
                    label: '全文',
                },
                {
                    value: 'title',
                    label: '标题',
                },
                {
                    value: 'tags',
                    label: '标签',
                },
                {
                    value: 'content',
                    label: '内容',
                },
            ],
            andOr: [
                {
                    label: '且',
                    value: 'and',
                },
                {
                    label: '或',
                    value: 'or',
                },
            ],
            // 单个高度
            ratio:
                parseFloat(
                    window
                        .getComputedStyle(
                            document.getElementsByTagName('html')[0],
                            null
                        )
                        .getPropertyValue('font-size')
                ) / 100,
            searchHeight: 0,
            searchType: 'fullText',
            searchValue: '',
            isAdvabde: false,
            defaultAdd: {
                isAndOr: 'or',
                searchType: 'fullText',
                value: '',
                id: '',
            },
            searchAddarr: [],
        }
    },
    watch: {
        // 高级筛选
        isAdvabde: {
            handler(newVal, oldVal) {
                if (newVal == true) {
                    this.addItem()
                } else {
                    this.searchAddarr.length = 0
                    this.commonForm.otherParams = JSON.stringify([
                        {
                            isAndOr: 'and',
                            searchType: this.searchType,
                            value: this.searchValue,
                        },
                    ])
                }
            },
        },
        searchType: {
            handler(newVal, oldVal) {
                if (this.searchAddarr.length == 0) {
                    this.isAdvabde = false
                    this.commonForm.otherParams = JSON.stringify([
                        {
                            isAndOr: 'and',
                            searchType: this.searchType,
                            value: this.searchValue,
                        },
                    ])
                    return
                }
                let arr = JSON.parse(JSON.stringify(this.searchAddarr))
                Reflect.deleteProperty(this.commonForm, this.searchType)
                arr.unshift({
                    isAndOr: 'and',
                    searchType: this.searchType,
                    value: this.searchValue,
                })
                this.commonForm.otherParams = JSON.stringify(arr)
            },
        },
        searchValue: {
            handler(newVal, oldVal) {
                if (this.searchAddarr.length == 0) {
                    this.isAdvabde = false
                    this.commonForm.otherParams = JSON.stringify([
                        {
                            isAndOr: 'and',
                            searchType: this.searchType,
                            value: this.searchValue,
                        },
                    ])
                    return
                }
                let arr = JSON.parse(JSON.stringify(this.searchAddarr))
                Reflect.deleteProperty(this.commonForm, this.searchType)
                arr.unshift({
                    isAndOr: 'and',
                    searchType: this.searchType,
                    value: this.searchValue,
                })
                this.commonForm.otherParams = JSON.stringify(arr)
            },
        },
        searchAddarr: {
            handler(newVal, oldVal) {
                if (newVal.length == 0) {
                    this.isAdvabde = false
                    this.commonForm.otherParams = JSON.stringify([
                        {
                            isAndOr: 'and',
                            searchType: this.searchType,
                            value: this.searchValue,
                        },
                    ])
                    return
                }
                let arr = JSON.parse(JSON.stringify(newVal))
                Reflect.deleteProperty(this.commonForm, this.searchType)
                arr.unshift({
                    isAndOr: 'and',
                    searchType: this.searchType,
                    value: this.searchValue,
                })
                this.commonForm.otherParams = JSON.stringify(arr)
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        commonForm: {
            get: function () {
                console.log(this.value)
                return this.value
            },
            set: newVal => {
                console.log(newVal)
                this.$emit('update', newVal)
                this.$emit('change', newVal)
            },
        },
    },
    methods: {
        onSearch() {
            console.log(1)
        },

        // 添加高级检索item
        addItem() {
            const obj = JSON.parse(JSON.stringify(this.defaultAdd))
            // obj.id = getUid(searchAddarr)
            // obj.id = this.getUid()
            this.searchAddarr.push(obj)
            this.$nextTick(() => {
                this.searchHeight = this.$refs.searchWrap.clientHeight
            })
        },
        reduceItem(index) {
            this.searchAddarr.splice(index, 1)
            this.$nextTick(() => {
                this.searchHeight = this.$refs.searchWrap.clientHeight
            })
        },
        onSearch() {
            this.$emit('search', this.commonForm)
        },
        // 生成唯一ID
        getUid() {
            const id = Number(
                Math.random().toString().substr(3, 3) + Date.now()
            ).toString(36)
            const flag = this.searchAddarr.some(item => item.id === id)
            if (flag) {
                this.getUid()
            } else {
                return id
            }
        },
    },
    mounted() {},
}
</script>
<style scoped lang="less">
.search {
    margin-bottom: 25px;
    display: flex;
    .item {
        margin-right: 10px;
        width: 100%;
        &:last-of-type {
            margin-right: 0;
        }
    }
    .btn {
        width: 120px;
        flex-shrink: 0;
        flex-grow: 0;
    }
    .keyword {
        width: 380px;
        flex-shrink: 0;
        flex-grow: 0;
    }
}
.ant-select {
    width: 100%;
}
/deep/.ant-input-affix-wrapper {
    &:hover {
        .ant-input {
            border-color: #79f9d9 !important;
        }
    }
}
/deep/.ant-input {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    background-color: #f5f5f5;
    border-radius: 3px;
    padding: 0 20px;
    border-color: transparent;
    &:hover {
        border-color: #79f9d9 !important;
    }
}
.submit {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    color: #000;
    background-color: #79f9d9;
    letter-spacing: 0;
    font-weight: bold;
    &:focus,
    &:hover {
        background-color: #79f9d9;
        border-color: #79f9d9;
        color: #000;
        box-shadow: 0 0 10px 0 rgb(121, 249, 217, 0.8);
    }
}
.advanced-search {
    margin-bottom: 30px;
}
.switch {
    margin-top: 10px;
}
:deep(.ant-switch-checked) {
    background-color: #79f9d9;
}
:deep(.ant-row) {
    margin-bottom: 10px;
}
.handle {
    height: 50px;
    display: flex;
    align-items: center;
    color: rgba(154, 154, 154, 100);
    & > div {
        margin-right: 0.12rem;
    }
}
</style>
