<template>
    <span class="tag" :type="type">{{name}}</span>
</template>
<script>
export default {
    props:['type','name']
}
</script>
<style scoped lang="less">
.tag{
    font-size: 16px;
    color: rgb(113, 137, 159);
    line-height: 30px;
    border-radius: 15px;
    margin-right: 5px;
    padding: 0 16px;
    display: inline-block;
    vertical-align: top;
    background-color: #dce8f3;
    margin-bottom: 10px;
    color: rgb(113, 137, 159);
    background-color: #dce8f3;
    &[type=de1fault]{
        color: rgb(113, 137, 159);
        background-color: #dce8f3;
    }
    &[type=pink]{
        background-color: rgb(235, 222, 222);
        color: rgb(185, 133, 133);
    }
    &[type=purple]{
        background-color: #e0deeb;
        color: rgb(144, 139, 171);
    }
    &[type=green]{
        background-color: #e4eedf;
        color: rgb(121, 150, 106);
    }
}
</style>