import request from '@/utils/request';
const baseURL = '/API/Collection';

export const ToggleCollectModel = function (data) {
    //
    return request({
        method: 'post',
        url: '/API/Collection/AddModel',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const ToggleCollectCard = function (data) {
    //
    return request({
        method: 'post',
        url: '/API/Collection/AddCard',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const GetMyModelList = function (data) {
    // 获取我收藏的模型列表
    return request({
        method: 'GET',
        url: '/API/Collection/GetMyModelList',
        params: data,
    });
};

export const GetMyCardList = function (data) {
    // 获取我收藏的卡片列表
    return request({
        method: 'GET',
        url: '/API/Collection/GetMyCardList',
        params: data,
    });
};

export const GetMyForumList = function (data) {
    // 获取我收藏的帖子
    return request({
        method: 'GET',
        url: '/API/Collection/GetMyForumList',
        params: data,
    });
};

export const ToggleCollectPost = function (data) {
    //
    return request({
        method: 'post',
        url: '/API/Collection/AddPost',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const ToggleCollectLibrary = function (data) {
    //
    return request({
        method: 'post',
        url: '/API/Collection/AddLibrary',
        data: data,
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const GetMyLibraryList = function (data) {
    // 获取我收藏的卡片列表
    return request({
        method: 'GET',
        url: '/API/Collection/GetMyLibraryList',
        params: data,
    });
};
